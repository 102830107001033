import React from "react";
import PropTypes from "prop-types";

export default function Submit({ name }) {
  return (
    <input
      type="submit"
      className="btn btn-block btn-lg btn-primary"
      value={name || "Odeslat"}
    />
  );
}

Submit.propTypes = {
  name: PropTypes.string,
};
