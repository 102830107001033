import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Glyphicon, Nav, Navbar } from "react-bootstrap";
import MonthName from "./text/MonthName.jsx";
import NumberWithSign from "./text/NumberWithSign";
import { withAuthorization } from "./AuthorizationContext.jsx";
import "./NavMenu.css";

class NavMenu extends Component {
  displayName = NavMenu.name;

  static byYears = (result, month) => {
    const index = result.findIndex((y) => y.year === month.year);
    if (index < 0) {
      result.push({
        year: month.year,
        balance: month.balance,
        months: [month],
      });
    } else {
      result[index].balance += month.balance;
      result[index].months.push(month);
    }

    return result;
  };

  static renderMonthList(months) {
    return months.map((m) => {
      return (
        <li key={m.year + "-" + m.month}>
          <Link to={`/fetchdata/${m.year}/${m.month}`}>
            <MonthName value={m.month} />
            <span className="pull-right" style={{ textAlign: "right", paddingRight: "20px" }}>
              <NumberWithSign value={m.balance} />
            </span>
          </Link>
        </li>
      );
    });
  }

  static renderYearsList(years, selectedYear, handleSelectYear) {
    return years.map((y) => (
      <React.Fragment key={y.year}>
        <li
          role="presentation"
          className={y.year === selectedYear.year ? "selected" : ""}
        >
          <Link to={`/fetchdata/${y.year}`} onClick={() => handleSelectYear({ year: y.year })}>
            {y.year}

            <div className="pull-right">
              <span style={{ paddingRight: "15px" }}>
                <NumberWithSign value={y.balance} />
              </span>

              {y.year === selectedYear.year ? (
                <Glyphicon glyph="chevron-down" className="pull-right" />
              ) : (
                <Glyphicon glyph="chevron-left" className="pull-right" />
              )}
            </div>
          </Link>
        </li>

        {selectedYear.year === y.year && (
          <ul className="nav navbar-nav">
            {NavMenu.renderMonthList(y.months)}
          </ul>
        )}
      </React.Fragment>
    ));
  }

  render() {
    const dataByYears = this.props.availableMonths.reduce(NavMenu.byYears, []);

    console.log(dataByYears);

    return (
      <Navbar fixedTop fluid collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to={"/"}>Moje peníze</Link>
          </Navbar.Brand>
          {this.props.authorization.isAuthenticated && <Navbar.Toggle />}
        </Navbar.Header>
        {this.props.authorization.isAuthenticated && (
          <Navbar.Collapse>
            <Nav>
              {NavMenu.renderYearsList(
                dataByYears,
                this.props.selectedYear,
                this.props.handleSelectYear
              )}
            </Nav>
          </Navbar.Collapse>
        )}
      </Navbar>
    );
  }
}

NavMenu.propTypes = {
  availableMonths: PropTypes.arrayOf(PropTypes.object),
  selectedYear: PropTypes.shape().isRequired,
  authorization: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
  }).isRequired,
  handleSelectYear: PropTypes.func.isRequired,
};

export default withAuthorization(NavMenu);
