import React from "react";
import PropTypes from "prop-types";

const Number = ({ value, fractionDigits }) => {
  return (
    <React.Fragment>
      {value.toLocaleString(undefined, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
      })}
    </React.Fragment>
  );
};

Number.propTypes = {
  value: PropTypes.number.isRequired,
  fractionDigits: PropTypes.number.isRequired
};

export default Number;
