import React, { Component } from "react";
import PropTypes from "prop-types";
import TestForm from "../components/TestForm";

export class Test extends Component {
  render() {
    return <TestForm t={this.props.t} />;
  }
}

Test.propTypes = {
  t: PropTypes.func.isRequired,
};
