import React from "react";
import PropTypes from "prop-types";

const MonthName = ({ value }) => {
  const date = new Date(`${value}/01/2000`);

  if (isNaN(date)) {
    return <React.Fragment></React.Fragment>
  } 

  const monthName = date.toLocaleString(undefined, { month: "long" });
  return <React.Fragment>{monthName}</React.Fragment>;
};

MonthName.propTypes = {
  value: PropTypes.number.isRequired
};

export default MonthName;
