import i18n from "i18next";

i18n.use("cz").init({
  // we init with resources
  resources: {
    cz: {
      translations: {
        "Required field": "Povinné pole",
        "Minimum length is {{length}} characters.":
          "Minimální délka je {{length}} znaků.",
        "Maximum length is {{length}} characters.":
          "Maximální délka je {{length}} znaků.",
        "Account movements": "Pohyby na účtu",
        "Account movement": "Pohyb na účtu",
        "No data for this month yet.":
          "Pro tento měsíc nejsou zatím žádná data.",
        Password: "Heslo",
        Email: "E-mail",
        Login: "Přihlásit",
        "E-mail should not be empty.": "E-mail nesmí být prázdný",
        "Password should not be empty.": "Heslo nesmí být prázdné",
        "E-mail is not in correct format.": "E-mail není ve správném formátu.",
        "E-mail or password is invalid": "E-mail nebo heslo nejsou správné",
        Occured: "Datum a čas",
        Account: "Účet",
        "To account": "Na účet",
        "Movement category": "Typ pohybu",
        Details: "Podrobnosti",
        Ammount: "Částka",
        Category: "Kategorie",
        Note: "Poznámka",
        "Text must be shorter than {{maxLength}} characters.":
          "Text musí být kratší než {{maxLength}} znaků.",
        entertainment: "zábava",
        drugstore: "drogerie",
        food: "jídlo",
        restaurant: "restaurace",
        health: "zdraví",
        home: "bydlení",
        permanent: "pravidelná platba",
        sallary: "plat",
        shopping: "nákupy",
        sport: "sport",
        withdrawal: "ATM",
        fuel: "benzín",
        holiday: "dovolená",
        clothing: "oblečení",
        car: "auto",
        investment: "investice",
        Close: "Zavřít",
        "Save changes": "Uložit",
        Revenues: "Příjmy",
        Expenses: "Výdaje",
        Investments: "Investice",
        "Total balance": "Příjmy celkem",
        "User's profile": "Profil uživatele",
      },
    },
  },
  lng: "cz",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
