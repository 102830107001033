import React from "react";
import PropTypes from "prop-types";
import SortGlyph from "./SortGlyph.jsx";

export default function SortingHeader({
  title,
  sortBy,
  sortDirection,
  sortedBy,
  onSortByChanged
}) {
  return (
    <th
      className="sortable"
      onClick={() => {
        onSortByChanged(sortBy);
      }}
    >
      {title}&nbsp;
      {sortedBy === sortBy && <SortGlyph sortDirection={sortDirection} />}
    </th>
  );
}

SortingHeader.propTypes = {
  title: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.oneOf(["asc", "desc"]),
  sortedBy: PropTypes.string.isRequired,
  onSortByChanged: PropTypes.func.isRequired
};
