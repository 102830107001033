import React from "react";
import { Glyphicon } from "react-bootstrap";
import PropTypes from "prop-types";

export default function SortGlyph({ sortDirection }) {
  return sortDirection === "asc" ? (
    <Glyphicon glyph="menu-up" />
  ) : (
    <Glyphicon glyph="menu-down" />
  );
}

SortGlyph.propTypes = {
  sortDirection: PropTypes.oneOf(["asc", "desc"])
};
