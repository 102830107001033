/* This is generated file, please do not change directly. */

const definition = function(t) {
  return {
    id: {
      type: "String",
      required: t("Required field"),
    },
    name: {
      type: "String",
      required: t("Required field"),
    },
  };
};

export default definition;
