/* This is generated file, please do not change directly. */
import UserProfileCategoryModel from "./UserProfileCategoryModel.js";

const definition = function(t) {
  return {
    firstName: {
      type: "String",
      required: t("Required field"),
    },
    lastName: {
      type: "String",
      required: t("Required field"),
    },
    email: {
      type: "String",
      required: t("Required field"),
    },
    categories: {
      type: UserProfileCategoryModel(t),
      isCollection: true,
      required: t("Required field"),
    },
  };
};

export default definition;
