import React from "react";
import PropTypes from "prop-types";
import { Form, Collection, Field, Input, Submit } from "./form";

import model from "../metadata/models/UserProfileModel";

export default function ProfileForm({ t, profile, handleSubmit, readonly }) {
  const onSubmit = handleSubmit;

  const m = model(t);

  return (
    <Form onSubmit={onSubmit} defaultValues={profile}>
      <h1>{t("User's profile")}</h1>

      <div className="row">
        <div className="col-md-5">
          <Field label="First name" name="firstName" verticalLayout={true}>
            <Input
              name="firstName"
              validation={m.firstName}
              readonly={readonly}
            />
          </Field>
          <Field label="Last name" name="lastName" verticalLayout={true}>
            <Input
              name="lastName"
              validation={m.lastName}
              readonly={readonly}
            />
          </Field>
          <Field label="E-mail" name="email" verticalLayout={true}>
            <Input name="email" validation={m.email} readonly={true} />
          </Field>
        </div>
      </div>

      <h3>Categories</h3>
      <Collection
        name="categories"
        renderField={(index) => {
          const idFiled = `categories[${index}].id`;
          const nameFiled = `categories[${index}].name`;

          return (
            <div className="row">
              <div className="col-sm-6">
                <Field label="ID" name={idFiled} verticalLayout={true}>
                  <Input
                    name={idFiled}
                    validation={m.categories.type.id}
                    readonly={readonly}
                  />
                </Field>
              </div>
              <div className="col-sm-6">
                <Field label="Name" name={nameFiled} verticalLayout={true}>
                  <Input
                    name={nameFiled}
                    validation={m.categories.type.name}
                    readonly={readonly}
                  />
                </Field>
              </div>
            </div>
          );
        }}
      />
      <Submit />
    </Form>
  );
}

ProfileForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
};
