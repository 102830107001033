import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

export default function Select({
  name,
  validation,
  options,
  readonly,
  keyProperty,
  titleProperty,
  focus,
}) {
  const { register } = useFormContext();
  const key = keyProperty || "value";
  const title = titleProperty || "text";

  const ref = useRef();

  useEffect(() => {
    if (ref.current && focus) {
      register(ref.current);
      ref.current.focus();
    }
  }, [focus, register]);

  return (
    <select
      name={name}
      className="form-control"
      readOnly={readonly}
      ref={(e) => {
        register(validation);
        ref.current = e;
      }}
    >
      {options.map((c) => (
        <option key={c[key]} value={c[key]}>
          {c[title]}
        </option>
      ))}
    </select>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  validation: PropTypes.object,
  readonly: PropTypes.bool,
  options: PropTypes.array.isRequired,
  keyProperty: PropTypes.string,
  titleProperty: PropTypes.string,
  focus: PropTypes.bool,
};
