import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import NavMenu from "./NavMenu.jsx";

export class Layout extends Component {
  displayName = Layout.name;

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col sm={3}>
            {this.props.isAuthenticated &&
              this.props.availableMonths.length > 0 && (
                <NavMenu
                  availableMonths={this.props.availableMonths}
                  selectedYear={this.props.selectedYear}
                  handleSelectYear={this.props.handleSelectYear}
                />
              )}
          </Col>
          <Col sm={9}>
            {this.props.isAuthenticated && (
              <Row>
                <Col sm={12}>
                  <div className="pull-right">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={this.props.handleLogOut}
                    >
                      Logout
                    </button>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>{this.props.children}</Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    );
  }
}

Layout.propTypes = {
  availableMonths: PropTypes.arrayOf(PropTypes.object),
  selectedYear: PropTypes.shape(),
  isAuthenticated: PropTypes.bool.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  handleSelectYear: PropTypes.func.isRequired,
};
