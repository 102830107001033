import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";

const toValidationState = (error) => {
  return error ? "field--error" : null;
};

export default function Field(props) {
  const { errors } = useFormContext();
  const { label, name, children, verticalLayout } = props;

  const customErrors = props.errors || {};
  const error = customErrors[name] || get(errors, name);

  if (verticalLayout) {
    return (
      <div className={toValidationState(error)}>
        <label>
          {label}:{children}
          {error && <span className="message">{error.message}</span>}
        </label>
      </div>
    );
  }

  return (
    <div className={toValidationState(error)}>
      <label>
        <div className="row">
          <div className="col-sm-4 field--label">{label}</div>
          <div className="col-sm-8">{children}</div>
        </div>
        {error && (
          <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <span className="message">{error.message}</span>
            </div>
          </div>
        )}
      </label>
    </div>
  );
}

Field.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  errors: PropTypes.object,
  verticalLayout: PropTypes.bool,
};
