import React, { Component } from "react";
import PropTypes from "prop-types";
import AccountMovementRow from "./AccountMovementRow.jsx";
import SortingHeader from "./SortingHeader.jsx";

class AccountMovementsGrid extends Component {
  displayName = AccountMovementsGrid.name;

  render() {
    const { t } = this.props;

    let contents = this.props.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <SortingHeader
                title={t("Occured")}
                {...this.props}
                sortBy="occured"
                sortedBy={this.props.sortBy}
              />
              <th>{t("Account")}</th>
              <th>{t("To account")}</th>
              <th>{t("Movement category")}</th>
              <th>{t("Details")}</th>
              <SortingHeader
                title={t("Ammount")}
                {...this.props}
                sortBy="ammount"
                sortedBy={this.props.sortBy}
              />
              <SortingHeader
                title={t("Category")}
                {...this.props}
                sortBy="category"
                sortedBy={this.props.sortBy}
              />
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.data.map(am => (
              <AccountMovementRow
                key={am.uniqueId}
                accountMovement={am}
                onEdit={this.props.onItemEdit}
                t={t}
              />
            ))}
          </tbody>
        </table>
      </div>
    );

    return <div>{contents}</div>;
  }
}

AccountMovementsGrid.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onItemEdit: PropTypes.func.isRequired,
  onSortByChanged: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.oneOf(["asc", "desc"]),
  t: PropTypes.func.isRequired
};

export default AccountMovementsGrid;
