import React, { Component } from "react";
import { Button, Glyphicon } from "react-bootstrap";
import PropTypes from "prop-types";
import DateTime from "../text/DateTime.jsx";
import Number from "../text/Number.jsx";

export default class AccountMovementRow extends Component {
  handleOnEdit = () => {
    this.props.onEdit(this.props.accountMovement);
  };

  render() {
    const am = this.props.accountMovement;
    const t = this.props.t;

    return (
      <tr>
        <td>
          <DateTime value={am.occured} format="DD.MM.YYYY hh:mm" />
        </td>
        <td>{am.account ? am.account : am.fromAccount}</td>
        <td>{am.toAccount}</td>
        <td>{am.movementCategory}</td>
        <td>{am.details}</td>
        <td style={{ textAlign: "right" }}>
          <Number value={am.ammount} fractionDigits={2} />
          &nbsp;Kč
        </td>
        <td>{t(am.category)}</td>
        <td>
          <Button bsSize="small" onClick={this.handleOnEdit}>
            <Glyphicon glyph="edit" />
          </Button>
        </td>
      </tr>
    );
  }
}

AccountMovementRow.propTypes = {
  accountMovement: PropTypes.shape().isRequired,
  onEdit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};
