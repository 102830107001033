import React from "react";

const AuthorizationContext = React.createContext({
  isAuthenticated: false,
  setUnauthorized: () => {},
  setAuthorized: () => {}
});

export default AuthorizationContext;

export function withAuthorization(Component) {
  return function ComponentWithAuthorization(props) {
    return (
      <AuthorizationContext.Consumer>
        {authorization => (
          <Component {...props} authorization={authorization} />
        )}
      </AuthorizationContext.Consumer>
    );
  };
}
