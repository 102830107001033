import React, { Component } from "react";
import { Route } from "react-router";
import { withTranslation } from "react-i18next";
import PrivateRoute from "./components/PrivateRoute.jsx";
import AuthorizationContext, {
  withAuthorization,
} from "./components/AuthorizationContext";
import { Layout } from "./components/Layout.jsx";
import { Login } from "./pages/Login.jsx";
import { Profile } from "./pages/Profile.jsx";
import { Test } from "./pages/Test.jsx";
import MonthSpendings from "./pages/MonthSpendings.jsx";

const LoginPage = withTranslation()(withAuthorization(Login));
const MonthSpendingsPage = withTranslation()(withAuthorization(MonthSpendings));
const ProfilePage = withTranslation()(withAuthorization(Profile));
const TestPage = withTranslation()(Test);

export default class App extends Component {
  displayName = App.name;

  constructor(props) {
    super(props);

    this.state = {
      availableMonths: [],
      authorization: { isAuthenticated: true },
    };
  }

  handleLoginSuccessful = () => {
    this.setState({ authorization: { isAuthenticated: true } });
    this.fetchData();
  };

  handleLogOut = () => {
    this.setState({ authorization: { isAuthenticated: false } });
  };

  handleSelectYear = (data) => {
    this.setState({
      selectedYear: data,
    });
  };

  componentDidMount() {
    fetch("api/account/isauthenticated", {
      method: "POST",
      credentials: "include",
    }).then((response) => {
      if (response.status === 401) {
        this.handleLogOut();
      } else {
        this.handleLoginSuccessful();
      }
    });
  }

  doLogOut() {
    fetch("api/account/logout", {
      method: "POST",
    }).then(() => {
      this.handleLogOut();
    });
  }

  fetchData() {
    const url = "api/monthstatistics/all";
    fetch(url)
      .then((response) => {
        if (response.status === 401) {
          return [];
        }

        return response.json();
      })
      .then((data) => {
        this.setState({
          availableMonths: data.reverse(),
          selectedYear: this.state.selectedYear
            ? this.state.selectedYear
            : data[0],
        });
      });
  }

  render() {
    return (
      <AuthorizationContext.Provider
        value={{
          ...this.state.authorization,
          setUnauthorized: this.handleLogOut,
          setAuthorized: this.handleLoginSuccessful,
        }}
      >
        <Layout
          availableMonths={this.state.availableMonths}
          selectedYear={this.state.selectedYear}
          handleSelectYear={this.handleSelectYear}
          isAuthenticated={this.state.authorization.isAuthenticated}
          handleLogOut={this.doLogOut.bind(this)}
        >
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/profile" component={ProfilePage} />
          <Route exact path="/test" component={TestPage} />
          <PrivateRoute
            exact
            path="/fetchdata/:year"
            render={(props) => (
              <MonthSpendingsPage
                {...props}
                handleSelectYear={this.handleSelectYear}
              />
            )}
          />
          <PrivateRoute
            exact
            path="/fetchdata/:year/:month"
            render={(props) => (
              <MonthSpendingsPage
                {...props}
                handleSelectYear={this.handleSelectYear}
              />
            )}
          />
        </Layout>
      </AuthorizationContext.Provider>
    );
  }
}
