import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import AccountMovementForm from "./AccountMovementForm";

class AccountMovementEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props.data };
    this.textInput = React.createRef();
  }

  render() {
    const t = this.props.t;

    return (
      <div className="static-modal">
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>
              {t("Account movement")}
              <Button onClick={this.props.onCancel} className="close">
                &times;
              </Button>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <AccountMovementForm
              data={this.state}
              onSubmit={this.props.onSave}
              t={t}
            />
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }
}

AccountMovementEditor.propTypes = {
  data: PropTypes.shape({
    occured: PropTypes.string.isRequired,
    movementCategory: PropTypes.string,
    details: PropTypes.string,
    ammount: PropTypes.number.isRequired,
    category: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default AccountMovementEditor;
