import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

export default function Input({ name, validation, readonly }) {
  const { register } = useFormContext();
  return (
    <input
      type="text"
      name={name}
      className="form-control"
      readOnly={readonly}
      ref={register(validation)}
    />
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  validation: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
};
