import React from "react";
import PropTypes from "prop-types";
import Number from "./Number.jsx";

const NumberWithSign = ({ value, textColor, fractionDigits = 2 }) => {
  const color = textColor ? textColor : value < 0 ? "red" : "green";

  return (
    <span style={{ color: color }}>
      {value > 0 && "+"}
      <Number value={value} fractionDigits={fractionDigits} />
    </span>
  );
};

NumberWithSign.propTypes = {
  value: PropTypes.number.isRequired,
  fractionDigits: PropTypes.number,
  color: PropTypes.string
};

export default NumberWithSign;
