import React from "react";
import * as moment from "moment";
import PropTypes from "prop-types";

const DateTime = ({ value, format }) => {
  return <React.Fragment>{moment(value).format(format)}</React.Fragment>;
};

DateTime.propTypes = {
  value: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
};

export default DateTime;
