import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import DateTime from "./text/DateTime.jsx";
import Number from "./text/Number.jsx";
import { Form, Field, Input, Select, Submit } from "./form";
import model from "../metadata/models/AccountMovementModel.js";

const compareCategoryByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }

  if (a.name > b.name) {
    return 1;
  }

  return 0;
};

export default function AccountMovementForm(props) {
  const { data, onSubmit, t } = props;
  const { occured, movementCategory, details, ammount } = data;
  const validations = model(t);

  const categories = [
    { id: "", name: "-" },
    { id: "entertainment", name: t("entertainment") },
    { id: "drugstore", name: t("drugstore") },
    { id: "food", name: t("food") },
    { id: "restaurant", name: t("restaurant") },
    { id: "health", name: t("health") },
    { id: "home", name: t("home") },
    { id: "permanent", name: t("permanent") },
    { id: "sallary", name: t("sallary") },
    { id: "shopping", name: t("shopping") },
    { id: "clothing", name: t("clothing") },
    { id: "sport", name: t("sport") },
    { id: "withdrawal", name: t("withdrawal") },
    { id: "fuel", name: t("fuel") },
    { id: "holiday", name: t("holiday") },
    { id: "investment", name: t("investment") },
    { id: "car", name: t("car") }
  ];

  const submit = (formData) => {
    onSubmit({ ...data, ...formData });
  };

  return (
    <div className="form-horizontal">
      <Form onSubmit={submit} defaultValues={data}>
        <Field name="occured" label={t("Occured")}>
          <span>
            <DateTime value={occured} format="DD.MM.YYYY hh:mm" />
          </span>
        </Field>

        <Field name="movementCategory" label={t("Movement category")}>
          <span>{movementCategory}</span>
        </Field>

        <Field name="details" label={t("Details")}>
          <span>{details}</span>
        </Field>

        <Field name="ammount" label={t("Ammount")}>
          <span>
            <Number value={ammount} fractionDigits={2} /> Kč
          </span>
        </Field>

        <Field name="category" label={t("Category")}>
          <Select
            name="category"
            keyProperty="id"
            titleProperty="name"
            options={categories.sort(compareCategoryByName)}
            focus={true}
          />
        </Field>

        <Field name="note" label={t("Note")}>
          <Input name="note" validation={validations.note} />
        </Field>

        <Row>
          <Col sm={12}>
            <span className="pull-right">
              <Submit name={t("Save changes")} />
            </span>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

AccountMovementForm.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
