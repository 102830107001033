import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ProfileForm from "../components/ProfileForm";

export function Profile({ t }) {
  const [data, setData] = useState({
    loading: true,
    saving: false,
    hasErrors: false,
  });

  useEffect(() => {
    async function fetchData() {
      var res = await fetch("api/account/profile");
      res
        .json()
        .then((res) =>
          setData((state) => {
            return { ...state, loading: false, profile: res };
          })
        )
        .catch(() => setData({ loading: false, hasErrors: true }));
    }

    fetchData();
  }, []);

  const postData = async (formData) => {
    setData({ ...data, saving: true });
    await fetch("api/account/profile", {
      method: "POST",
      body: JSON.stringify(formData),
    });

    setData({ ...data, saving: false });
  };

  return (
    <div>
      {data.loading && <div>Loading</div>}
      {data.profile && (
        <ProfileForm
          t={t}
          profile={data.profile}
          handleSubmit={postData}
          readonly={data.saving}
        />
      )}
    </div>
  );
}

Profile.propTypes = {
  t: PropTypes.func.isRequired,
};
