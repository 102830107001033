import React from "react";
import PropTypes from "prop-types";
import { useFieldArray } from "react-hook-form";

export default function Collection({ name, renderField }) {
  const { fields, append, remove } = useFieldArray({
    name: name,
  });

  return (
    <div className="collection-container">
      {fields.map((field, index) => (
        <div className="collection-row" key={field.id}>
          <div className="collection-row__content">
            {renderField(index, field)}
          </div>

          <div className="collection-row__control-button">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => remove(index)}
            >
              <span
                className="glyphicon glyphicon-remove"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      ))}

      <div className="collection-row">
        <div className="collection-row__content"></div>
        <div className="collection-row__control-button">
          <button type="button" className="btn btn-success" onClick={append}>
            <span
              className="glyphicon glyphicon-plus"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </div>
  );
}

Collection.propTypes = {
  name: PropTypes.string.isRequired,
  renderField: PropTypes.func.isRequired,
};
