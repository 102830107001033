import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Panel } from "react-bootstrap";
import MonthName from "../components/text/MonthName.jsx";
import MonthBalance from "../components/MonthBalance.jsx";
import SpendingChart from "../components/SpendingChart.jsx";
import AccountMovementsGrid from "../components/grid/AccountMovementsGrid.jsx";
import AccounMovementEditor from "../components/AccountMovementEditor.jsx";
import Loader from "../components/Loader";

const AbortController = window.AbortController;

export default class MonthSpendings extends Component {
  displayName = MonthSpendings.name;
  state = {
    data: [],
    loading: true,
    editingItem: null,
    sortDirection: "asc",
    sortBy: "occured",
  };
  abortController = new AbortController();

  componentDidMount() {
    if (!this.props.authorization.isAuthenticated) {
      return;
    }

    const params = this.props.match.params;
    this.fetchData(params);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.authorization.isAuthenticated) {
      return;
    }

    const currentParams = this.props.match.params;
    const newParams = nextProps.match.params;

    if (
      currentParams.year !== newParams.year ||
      currentParams.month !== newParams.month
    ) {
      this.setState({ loading: true });
      this.fetchData(newParams);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  sortData = (data, sortBy, sortDirection) => {
    let sortFunction;

    const t = this.props.t;
    const getValue = (i) => i[sortBy];
    const sortNumber = (a, b) => getValue(a) - getValue(b);
    const sortDate = (a, b) => new Date(getValue(a)) - new Date(getValue(b));

    const getNonEmptyStringValue = (i) => {
      var value = getValue(i);
      if (value) {
        return value;
      }

      return "";
    };
    const sortLocalizedString = (a, b) => {
      return t(getNonEmptyStringValue(a)).localeCompare(
        t(getNonEmptyStringValue(b))
      );
    };

    if (sortBy === "ammount") {
      sortFunction = sortNumber;
    }

    if (sortBy === "occured") {
      sortFunction = sortDate;
    }

    if (sortBy === "category") {
      sortFunction = sortLocalizedString;
    }

    let sortFunctionWithOrder;
    if (sortDirection === "desc") {
      sortFunctionWithOrder = (a, b) => sortFunction(a, b) * -1;
    } else {
      sortFunctionWithOrder = sortFunction;
    }

    return data.sort(sortFunctionWithOrder);
  };

  fetchData = (params) => {
    const url = params.month
      ? `api/accountmovements/bydate/${params.year}/${params.month}`
      : `api/accountmovements/bydate/${params.year}`;
    fetch(url, { signal: this.abortController.signal })
      .then((response) => {
        if (response.status === 401) {
          this.props.authorization.setUnauthorized();
          throw Error(response.statusText);
        }

        return response.json();
      })
      .then((data) => {
        const dataSorted = this.sortData(
          data,
          this.state.sortBy,
          this.state.sortDirection
        );
        this.setState({ data: dataSorted, loading: false });
        this.props.handleSelectYear({ year: parseInt(params.year, 10) });
      })
      .catch(() => {});
  };

  handleItemEdit = (item) => {
    this.setState({ editingItem: item });
  };

  handleSortByChange = (sortBy) => {
    const reversedDirection =
      this.state.sortDirection === "asc" ? "desc" : "asc";
    const newSortDirection =
      this.state.sortBy !== sortBy ? "asc" : reversedDirection;

    const newData = this.sortData(this.state.data, sortBy, newSortDirection);
    this.setState({
      data: newData,
      sortBy: sortBy,
      sortDirection: newSortDirection,
    });
  };

  handleItemEditCancel = () => {
    this.setState({ editingItem: null });
  };

  handleItemEditSave = (item) => {
    fetch("/api/accountmovements", {
      method: "PATCH",
      body: JSON.stringify(item),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(() => {
      this.setState({ editingItem: null });
      this.fetchData(this.props.match.params);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const { year, month } = this.props.match.params;
    const t = this.props.t;

    const header = (
      <h1>
        {t("Account movements")} - <MonthName value={Number(month)} />{" "}
        {Number(year)}{" "}
      </h1>
    );

    if (this.state.data.length === 0) {
      return (
        <div>
          {header}
          <p className="empty-text">{t("No data for this month yet.")}</p>
        </div>
      );
    }

    return (
      <div>
        {header}

        <Panel>
          <Row>
            <Col md={9}>
              <SpendingChart data={this.state.data} t={t} />
            </Col>
            <Col md={3} style={{ marginTop: "1.5em" }}>
              <MonthBalance data={this.state.data} />
            </Col>
          </Row>
        </Panel>

        <AccountMovementsGrid
          loading={this.state.loading}
          data={this.state.data}
          onItemEdit={this.handleItemEdit}
          onSortByChanged={this.handleSortByChange}
          sortBy={this.state.sortBy}
          sortDirection={this.state.sortDirection}
          t={t}
        />
        {this.state.editingItem && (
          <AccounMovementEditor
            data={this.state.editingItem}
            onCancel={this.handleItemEditCancel}
            onSave={this.handleItemEditSave}
            t={t}
          />
        )}
      </div>
    );
  }
}

MonthSpendings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      year: PropTypes.string.isRequired,
      month: PropTypes.string,
    }).isRequired,
  }).isRequired,
  authorization: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    setUnauthorized: PropTypes.func.isRequired,
  }).isRequired,
  handleSelectYear: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
