import React from "react";
import PropTypes from "prop-types";
import {
  Form,
  Collection,
  Field,
  Input,
  InputNumber,
  InputDate,
  Select,
  Submit,
} from "./form";

import model from "../metadata/models/TestModel";

export default function LoginForm(props) {
  const onSubmit = (data) => alert(JSON.stringify(data));

  const m = model(props.t);

  const dropdownOptions = [
    { text: "-", value: "" },
    { text: "India", value: "india" },
    { text: "Canada", value: "canada" },
  ];

  return (
    <div className="row">
      <div className="col-md-5">
        <Form
          onSubmit={onSubmit}
          defaultValues={{
            requiredInteger: 12345,
            optionalDate: new Date(),
            foo: [{ name: "kuk" }],
          }}
        >
          <h2>Test form</h2>
          <h3>Main data</h3>

          <Field label="Number" name="requiredInteger">
            <InputNumber
              name="requiredInteger"
              validation={m.requiredInteger}
            />
          </Field>
          <Field label="Date" name="optionalDate">
            <InputDate name="optionalDate" validation={m.optionalDate} />
          </Field>

          <Field label="State" name="state">
            <Select name="state" options={dropdownOptions} />
          </Field>

          <h3>Contant persons</h3>
          <Collection
            name="foo"
            renderField={(index) => {
              const firstNameFiled = `foo[${index}].firstName`;
              const lastNameFiled = `foo[${index}].lastName`;

              return (
                <div>
                  <Field label="First name" name={firstNameFiled}>
                    <Input name={firstNameFiled} validation={m.foo.type.name} />
                  </Field>

                  <Field label="Last name" name={lastNameFiled}>
                    <Input name={lastNameFiled} validation={m.foo.type.name} />
                  </Field>
                </div>
              );
            }}
          />
          <Submit />
        </Form>
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
};
