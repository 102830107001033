/* This is generated file, please do not change directly. */

const definition = function(t) {
  return {
    uniqueId: {
      type: "String",
    },
    partitionKey: {
      type: "String",
    },
    occured: {
      type: "DateTime",
      required: t("Required field"),
    },
    account: {
      type: "String",
    },
    fromAccount: {
      type: "String",
    },
    toAccount: {
      type: "String",
    },
    details: {
      type: "String",
    },
    movementCategory: {
      type: "String",
    },
    ammount: {
      type: "Decimal",
      required: t("Required field"),
    },
    category: {
      type: "String",
    },
    note: {
      type: "String",
      minLength: { value: 0, message: t("Minimum length is {{length}} characters.", { length: 0 }) },
      maxLength: { value: 100, message: t("Maximum length is {{length}} characters.", { length: 100 }) },
    },
  };
};

export default definition;
