import React from "react";
import PropTypes from "prop-types";
import { useForm, FormContext } from "react-hook-form";

export default function Form(props) {
  const methods = useForm({ defaultValues: props.defaultValues });

  const submit = (e) => {
    e.preventDefault();
    methods.handleSubmit(props.onSubmit)();
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={submit}>{props.children}</form>
    </FormContext>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
