import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import { withAuthorization } from "./AuthorizationContext";

const PrivateRoute = ({
  component: Component,
  render,
  authorization,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (authorization.isAuthenticated) {
        if (render) {
          return render(props);
        }

        return <Component {...props} {...this.props} />;
      }

      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      );
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any,
  render: PropTypes.func,
  location: PropTypes.string,
  authorization: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired
  }).isRequired
};

export default withAuthorization(PrivateRoute);
