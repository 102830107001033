/* This is generated file, please do not change directly. */
import Foo from "./Foo.js";

const definition = function(t) {
  return {
    requiredInteger: {
      type: "Int32",
      required: t("Required field"),
    },
    optionalDate: {
      type: "DateTime",
    },
    foo: {
      type: Foo(t),
      isCollection: true,
    },
  };
};

export default definition;
