import React, { Component } from "react";
import { BarChart } from "react-easy-chart";
import PropTypes from "prop-types";
import Tooltip from "./Tooltip.jsx";
import Number from "./text/Number.jsx";

class SpendingChart extends Component {
  displayName = SpendingChart.name;
  state = { showTooltip: false };

  selectChartData = (data) => {
    const t = this.props.t;

    const allSpendings = data.filter((x) => x.ammount < 0 && x.category !== "investment");
    const spendingsSummary = allSpendings.reduce((acc, v) => {
      const category = v.category ? v.category : "other";
      const current = acc[category] ? acc[category] : 0;
      acc[category] = current + Math.abs(v.ammount);
      return acc;
    }, {});

    const colors = [
      "#47546a",
      "#55657f",
      "#637694",
      "#7186aa",
      "#7f97bf",
      "#8da8d4",
      "#98b1d8",
      "#a4b9dd",
      "#afc2e1",
      "#bbcbe5",
      "#c6d4ea",
      "#0e1115",
      "#1c222a",
      "#2a3240",
      "#384355",
    ];

    const chartData = Object.keys(spendingsSummary).map((key, index) => {
      return { x: t(key), y: spendingsSummary[key], color: colors[index] };
    });

    return chartData.sort((a, b) => b.y - a.y);
  };

  mouseOverHandler = (d, e) => {
    this.setState({
      showToolTip: true,
      top: `${e.offsetY - 10}px`,
      left: `${e.offsetX + 10}px`,
      y: d.y,
      x: d.x,
    });
  };

  mouseMoveHandler = (d, e) => {
    if (this.state.showToolTip) {
      this.setState({
        top: `${e.offsetY - 10}px`,
        left: `${e.offsetX + 10}px`,
      });
    }
  };

  mouseOutHandler = () => {
    this.setState({ showToolTip: false });
  };

  createTooltip = () => {
    if (this.state.showToolTip) {
      return (
        <Tooltip left={this.state.left} top={this.state.top}>
          {this.state.x}: <Number value={this.state.y} fractionDigits={2} /> Kč
        </Tooltip>
      );
    }

    return false;
  };

  render() {
    const data = this.selectChartData(this.props.data);

    return (
      <div
        style={{
          position: "relative",
          overflow: "scroll",
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        {this.createTooltip()}
        <BarChart
          axes
          data={data}
          mouseOverHandler={this.mouseOverHandler}
          mouseOutHandler={this.mouseOutHandler}
          mouseMoveHandler={this.mouseMoveHandler}
        />
      </div>
    );
  }
}

SpendingChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      ammount: PropTypes.number.isRequired,
    })
  ).isRequired,
  t: PropTypes.func.isRequired,
};

export default SpendingChart;
