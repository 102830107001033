import React from "react";
import PropTypes from "prop-types";
import Form from "./form/Form";
import Field from "./form/Field";
import Input from "./form/Input";
import InputPassword from "./form/InputPassword";
import Submit from "./form/Submit";

export default function LoginForm(props) {
  const { onSubmit, t, serverErros } = props;

  const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <div>
      <h1>Přihlášení</h1>
      <div className="row">
        <div className="col-lg-4">
          <Form onSubmit={onSubmit}>
            <Field label={t("E-mail")} name="email">
              <Input
                name="email"
                validation={{
                  required: t("E-mail should not be empty."),
                  pattern: {
                    value: emailPattern,
                    message: t("E-mail is not in correct format."),
                  },
                }}
              />
            </Field>

            <Field label={t("Password")} name="password" errors={serverErros}>
              <InputPassword
                name="password"
                validation={{ required: t("Password should not be empty.") }}
              />
            </Field>

            <div className="row">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <Submit value={t("Login")} name="Přihlásit" />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  serverErros: PropTypes.object,
  t: PropTypes.func.isRequired,
};
