import React from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import { Controller } from "react-hook-form";
import cs from "date-fns/locale/cs";

import "react-datepicker/dist/react-datepicker.css";
import "./InputDate.css";

registerLocale("cs", cs);

export default function InputDate({ name, validation }) {
  return (
    <Controller
      as={DatePicker}
      valueName="selected" // DateSelect value's name is selected
      name={name}
      rules={validation}
      dateFormat="dd.MM.yyyy"
      className="form-control"
      locale="cs"
      shouldCloseOnSelect={true}
    />
  );
}

InputDate.propTypes = {
  name: PropTypes.string.isRequired,
  validation: PropTypes.object.isRequired,
};
