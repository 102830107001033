import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import LoginForm from "../components/LoginForm";

export class Login extends Component {
  state = { email: "", password: "", errors: {} };

  handleSubmit = (data) => {
    fetch("api/account/login", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.email) {
          throw new Error();
        }

        this.props.authorization.setAuthorized();
      })
      .catch(() => {
        this.setState({
          errors: {
            password: {
              message: this.props.t("E-mail or password is invalid"),
            },
          },
        });
      });
  };

  render() {
    if (this.props.authorization.isAuthenticated) {
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      return <Redirect to={{ pathname: `/fetchdata/${year}/${month}` }} />;
    }

    const { t } = this.props;

    return (
      <LoginForm
        onSubmit={this.handleSubmit.bind(this)}
        serverErros={this.state.errors}
        t={t}
      />
    );
  }
}

Login.propTypes = {
  authorization: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    setAuthorized: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};
