import React from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

export default function InputNumber({ name, validation }) {
  const { control } = useFormContext();

  const handleChange = ([e]) => {
    const value = parseInt(e.target.value, 10);

    if (isNaN(value)) {
      return "";
    }

    return value;
  };

  return (
    <Controller
      name={name}
      control={control}
      onChange={handleChange}
      rules={validation}
      className="form-control"
      autoComplete="off"
      as={<input />}
    />
  );
}

InputNumber.propTypes = {
  name: PropTypes.string.isRequired,
  validation: PropTypes.object.isRequired,
};
