import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import NumberWithSign from "./text/NumberWithSign.jsx";
import { useTranslation } from "react-i18next";

const MonthBalance = props => {
  const sum = (sum, accountMovement) => sum + accountMovement.ammount;
  const movementsWithoutInvestments = props.data.filter(m => m.category !== "investment");
  const investments = props.data
    .filter(m => m.category === "investment")
    .reduce(sum, 0);

  const balance = movementsWithoutInvestments.reduce(sum, 0);

  const revenues = movementsWithoutInvestments
    .filter(m => m.ammount > 0)
    .reduce(sum, 0);

  const expenses = movementsWithoutInvestments
    .filter(m => m.ammount < 0)
    .reduce(sum, 0);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row style={{ paddingBottom: ".5em" }}>
        <Col xs={6}>{t("Revenues")}</Col>
        <Col xs={6} className="text-right">
          <NumberWithSign value={revenues} fractionDigits={2} />
        </Col>
      </Row>

      <Row style={{ paddingBottom: ".5em" }}>
        <Col xs={6}>{t("Expenses")}</Col>
        <Col xs={6} className="text-right">
          <NumberWithSign value={expenses} fractionDigits={2} />
        </Col>
      </Row>

      <Row style={{ paddingBottom: ".5em" }}>
        <Col xs={6}>{t("Investments")}</Col>
        <Col xs={6} className="text-right">
            <NumberWithSign value={investments} fractionDigits={2} textColor="#337ab7" />
        </Col>
      </Row>
      <hr />

      <Row>
        <Col xs={6}>{t("Total balance")}</Col>
        <Col xs={6} className="text-right">
          <NumberWithSign value={balance} fractionDigits={2} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

MonthBalance.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      ammount: PropTypes.number.isRequired
    })
  )
};

export default MonthBalance;
