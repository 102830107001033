import React from "react";
import PropTypes from "prop-types";

const Tooltip = ({ left, top, children }) => {
  return (
    <div
      style={{
        position: "absolute",
        left: left,
        top: top,
        background: "white",
        border: "solid 1px black",
        borderRadius: 5,
        padding: 5
      }}
    >
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  left: PropTypes.string.isRequired,
  top: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Tooltip;
